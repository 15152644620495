import * as React from 'react'
import { cloneElement } from 'react'
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
  useNotify
} from 'react-admin'
import BuildIcon from '@material-ui/icons/Build'
import app from '../../../client/feathersClient'

const ListActions = (props) => {
  // eslint-disable-next-line react/prop-types
  const { className, exporter, filters, maxResults, ...rest } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
    total
  } = useListContext()
  const notify = useNotify()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button'
        })}
      {hasCreate && <CreateButton basePath={basePath} />}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />

      <Button
        onClick={async () => {
          try {
            await app.service('gensco/integrate').create({})
            notify('Integration started')
          } catch (e) {
            console.log('e ===', e)
            notify('Ups, something went wrong', 'warning')
          }
        }}
        label='Start integration'
      >
        <BuildIcon />
      </Button>
    </TopToolbar>
  )
}

export default ListActions
