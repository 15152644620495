import * as React from 'react'
import MultipleImageInput from '../../elements/MultipleImageInput'

const CustomEditSettings = (props) => {
  console.log(props.source)
  if (props.record.type === 'images') {
    return <MultipleImageInput {...props} />
  }
  return <>Edit type: {props.record.type}</>
}

export default CustomEditSettings
