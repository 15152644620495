import {
  TextField,
  Edit,
  ReferenceField,
  SimpleForm,
  TextInput
} from 'react-admin'
import React from 'react'
import { Typography } from '@material-ui/core'
import useCommonStyles from '../../hooks/useCommonStyles'

const CustomerEdit = (props) => {
  const classes = useCommonStyles()
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceField
          source='companyId'
          reference='companies'
          label='Company'
        >
          <TextField source='companyName' />
        </ReferenceField>

        <Typography variant='h6' gutterBottom>
          Identity
        </Typography>

        <TextInput
          source='identity.firstName'
          label='First Name'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='identity.lastName'
          label='Last Name'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='identity.email'
          label='Email'
          type='email'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='identity.phone'
          label='Phone number'
          type='tel'
          formClassName={classes.inlineBlock}
        />

        <Typography variant='h6' gutterBottom>
          Address
        </Typography>

        <TextInput
          source='address.address1'
          label='Address'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address.address2'
          label='Address (line 2)'
          formClassName={classes.inlineBlock}
        />
        <div />

        <TextInput
          source='address.city'
          label='City'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address.state'
          label='State'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address.zip'
          label='Zip'
          formClassName={classes.inlineBlock}
        />

        <Typography variant='h6' gutterBottom>
          Notes
        </Typography>

        <TextInput source='notes' label='Notes' fullWidth />
        <TextInput source='leadSource' label='Lead Source' fullWidth />
      </SimpleForm>
    </Edit>
  )
}

export default CustomerEdit
