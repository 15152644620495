import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  demo: {
    backgroundColor: '#e4e4e4'
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}))

export default function LotIdList({ data }) {
  const classes = useStyles()
  const sorted = Object.keys(data)
    .map((lotId) => ({ lotId, ...data[lotId] }))
    .sort((x, y) => (x.isValid === y.isValid ? 0 : x.isValid ? 1 : -1))
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant='h6' className={classes.title}>
            Matched
          </Typography>
          <div className={classes.demo}>
            <List>
              {sorted.map((element) => (
                <React.Fragment key={element.lotId}>
                  <ListItem>
                    <ListItemText
                      primary={`"${element.lotId}"`}
                      secondary={`Occurs ${element.times} times - ${
                        element.isValid ? 'is' : 'not'
                      } valid`}
                      secondaryTypographyProps={{
                        color: element.isValid ? 'secondaryText' : 'error'
                      }}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </>
  )
}
