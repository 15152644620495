import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import {
  Typography,
  Input as MUIInput,
  Button,
  Switch
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import LotIdList from './LotIdTable'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  text: {
    background: 'rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
    width: '236px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'rgba(0, 0, 0, 0.54)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    margin: '10px 0 !important',
    padding: '10px 10px'
  },
  img: {
    margin: '0.5rem',
    maxHeight: '10rem',
    cursor: 'pointer'
  }
}))

const Input = ({ widget, formProps, values = [], rest }) => {
  const styles = useStyles()

  const props = rest
  const [images, setPictures] = useState(props.record[props.source])
  const [isUpdateNext, setUpdateNext] = useState(true)
  const [match, setMatch] = useState({})

  useEffect(() => {
    setPictures([...images, ...values])
    if (props.withLotId) validateLotIds([...images, ...values])
    formProps.input.onChange([...images, ...values])
  }, [values])

  const changeItem = (index, data, changeNext) => {
    let copy = [...images]
    copy.splice(index, 1, { ...copy[index], ...data })
    if (changeNext && copy.length > index + 1) {
      copy = copy.map((img, newIndex) => {
        if (index < newIndex) {
          return { ...copy[newIndex], ...data }
        }
        return img
      })
    }

    setPictures(copy)
    if (props.withLotId) validateLotIds(copy)
    formProps.input.onChange(copy)
  }

  const removeElement = (index, withLotId = true) => {
    if (index === null) {
      setPictures([])
      formProps.input.onChange([])
      return
    }
    const copy = [...images]
    copy.splice(index, 1)
    setPictures(copy)
    if (withLotId) validateLotIds(copy)
    formProps.input.onChange(copy)
  }

  const validateLotIds = (images) => {
    const newMatch = {}
    images.forEach((img) => {
      const lotId = img.lotId
      newMatch[lotId] = {
        times: (newMatch[lotId]?.times || 0) + 1,
        isValid: !!props.record.file.find((file) => file.lotId === lotId)
      }
    })
    setMatch(newMatch)
  }
  return (
    <>
      {images.map((img, index) => {
        if (!img) return
        return (
          <Paper key={img.publicId} style={{ margin: '30px 0', width: 'auto' }}>
            <MUIInput
              style={{ margin: '0 5% 15px 5%', width: '90%', paddingTop: 15 }}
              value={img.title}
              placeholder={'Title'}
              onChange={(e) => changeItem(index, { title: e.target.value })}
            />

            <Grid container spacing={2}>
              <Grid
                item
                xs
                direction={'column'}
                container
                alignItems={'center'}
              >
                <img className={styles.img} src={img.url} />
              </Grid>
              <Grid item xs container direction='column' spacing={2}>
                <Grid
                  item
                  container
                  direction='row'
                  alignItems={'center'}
                  spacing={2}
                >
                  {props.withLotId && (
                    <>
                      <Typography style={{ marginRight: '15px' }}>
                        {'Lot #'}
                      </Typography>
                      <MUIInput
                        value={img.lotId}
                        placeholder={'lotId'}
                        onChange={(e) =>
                          changeItem(
                            index,
                            { lotId: e.target.value },
                            isUpdateNext
                          )
                        }
                      />
                    </>
                  )}
                </Grid>
                <Grid item>
                  <Button onClick={() => removeElement(index, props.withLotId)}>
                    Remove
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )
      })}
      <Typography className={styles.text} onClick={widget.open}>
        {`Upload Image ${props.required ? '*' : ''}`}
        <AddIcon color='inherit' />
      </Typography>
      <Button
        variant='contained'
        onClick={() => removeElement(null, props.withLotId)}
      >
        Remove All
      </Button>
      {props.withLotId && (
        <>
          <div
            style={{
              margin: '16px 0'
            }}
          >
            <Switch
              checked={isUpdateNext}
              onChange={() => setUpdateNext(!isUpdateNext)}
              name={'"LotId" updates will change all images bellow.'}
            />
            <Typography component={'span'}>
              {'"LotId" updates will change all images bellow.'}
            </Typography>
          </div>
          <LotIdList data={match} />
        </>
      )}
    </>
  )
}

const MultipleImageInput = (props) => {
  const [widget, setWidget] = useState({})
  const [values, setValues] = useState([])

  useEffect(() => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_UPLOAD_PRESET,
        fieldName: props.source,
        multiple: true,
        sources: ['local', 'url', 'camera']
      },
      (error, result) => {
        if (result && result.event === 'queues-end') {
          const files = result.info.files.map((file) => {
            if (!file.uploadInfo) return
            return {
              params: '',
              url: `https://res.cloudinary.com/${process.env.REACT_APP_CLOUD_NAME}/image/upload/${file.uploadInfo.path}`,
              publicId: file.uploadInfo.public_id,
              lotId: ''
            }
          })
          console.log('files', files)
          setValues(files)
        } else {
          console.log('else files')
        }
        if (error) {
          console.log(error)
        }
      }
    )
    setWidget(widget)
  }, [props.source])

  return (
    <Field name={props.source}>
      {(formProps) => {
        return (
          <Input
            formProps={formProps}
            widget={widget}
            values={values}
            rest={props}
          />
        )
      }}
    </Field>
  )
}

export default MultipleImageInput
