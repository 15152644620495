import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ReferenceField,
  TextField
} from 'react-admin'
import React from 'react'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => <Filter {...props}>{DateFilters}</Filter>

const CustomerList = (props) => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<Filters />}
  >
    <Datagrid>
      <ReferenceField source='companyId' reference='companies' label='Company'>
        <TextField source='companyName' />
      </ReferenceField>
      <TextField source='identity.firstName' label='First Name' />
      <TextField source='identity.lastName' label='Last Name' />
      <TextField source='identity.email' label='Email' />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)

export default CustomerList
