import RichTextInput from 'ra-input-rich-text'
import React, { useEffect } from 'react'
import { useFormState, useForm } from 'react-final-form'

const RichText = (props) => {
  const state = useFormState()
  const form = useForm()
  const configSection = state.values.config?.sections || []
  useEffect(() => {
    configSection.map((el, i) => {
      if (el === undefined) {
        form.change(`config.sections[${i}]`, '')
      }
    })
  }, [configSection])

  return (
    <>
      <RichTextInput
        source='config.sections[0]'
        label='Section 1'
        fullWidth
        {...props}
      />
      <RichTextInput
        source='config.sections[1]'
        label='Section 2'
        fullWidth
        {...props}
      />
      <RichTextInput
        source='config.sections[2]'
        label='Section 3'
        fullWidth
        {...props}
      />
      <RichTextInput
        source='config.sections[3]'
        label='Section 4'
        fullWidth
        {...props}
      />
    </>
  )
}

export default RichText
