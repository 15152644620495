import {
  DateField,
  TextField,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin'
import React from 'react'
import Change from '../../elements/Change'

const BidsChange = (props) => {
  return (
    <Change {...props}>
      <SimpleForm redirect={!props.id ? 'edit' : false}>
        <TextField source={'id'} />
        <TextInput source={'companyId'} validate={required()} />
        <TextInput source={'customerId'} validate={required()} />
        <TextInput source={'status'} validate={required()} />
        <TextInput source={'jobType'} />
        <TextInput source={'taxJurisdiction'} />
        <TextInput source={'notes'} />
        <TextInput source={'privateNotes'} />
        <TextInput source={'exclusions'} />
        <ArrayInput source={'options'}>
          <SimpleFormIterator>
            <TextInput source={'id'} />
            <TextInput source={'name'} />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source={'elements'}>
          <SimpleFormIterator>
            <TextInput source={'name'} />
            <TextInput source={'qty'} />
            <TextInput source={'price'} />
            <TextInput source={'type'} />
            <TextInput source={'itemID'} />
            <ArrayInput source={'options'}>
              <SimpleFormIterator>
                <TextInput />
              </SimpleFormIterator>
            </ArrayInput>
            <TextInput source={'discount'} />
          </SimpleFormIterator>
        </ArrayInput>

        <NumberInput source={'salesTax'} />
        <TextInput source={'selectedOption'} />
        <TextInput
          source={'subtotals'}
          format={(v) => {
            return JSON.stringify(v, null, 4)
          }}
          parse={(v) => {
            return JSON.parse(v)
          }}
          multiline
          fullWidth
          helperText={'Only number value can be changed here'}
        />
        <TextInput
          source={'totals'}
          format={(v) => {
            return JSON.stringify(v, null, 4)
          }}
          parse={(v) => {
            return JSON.parse(v)
          }}
          helperText={'Only number value can be changed here'}
          multiline
          fullWidth
        />

        <DateField source='createdAt' label='Created at' showTime />
        <DateField source='updatedAt' label='Updated at' showTime />
      </SimpleForm>
    </Change>
  )
}

export default BidsChange
