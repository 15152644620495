import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput
} from 'react-admin'
import React from 'react'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput label='Email' source='email.$regex' alwaysOn />
    {DateFilters}
  </Filter>
)

const AdminList = (props) => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<Filters />}
  >
    <Datagrid>
      <TextField source='username' label='username' />
      <TextField source='email' label='email' />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)

export default AdminList
