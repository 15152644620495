import React from 'react'
import feathersClient from './client/feathersClient'
import { authClient, restClient } from 'ra-data-feathers'
import { Admin, Resource } from 'react-admin'

import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Person from '@material-ui/icons/Person'
import PeopleIcon from '@material-ui/icons/People'
import BuildIcon from '@material-ui/icons/Build'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import SettingsIcon from '@material-ui/icons/Settings'

import { SessionList } from './models/sessions'
import AdminCreate from './models/admins/Create'
import AdminEdit from './models/admins/Edit'
import AdminList from './models/admins/List'
import CustomerList from './models/customers/List'
import CustomerCreate from './models/customers/Create'
import CustomerEdit from './models/customers/Edit'
import CompanyList from './models/companies/List'
import CompanyCreate from './models/companies/Create'
import CompanyEdit from './models/companies/Edit'
import { PartsList, PartsShow } from './models/gensco/parts'
import BidList from './models/bids/List'
import BidChange from './models/bids/Change'
import SettingsList from './models/settings/List'
import SettingsEdit from './models/settings/Edit'

const restClientOptions = {
  shortId: 'id',
  id: '_id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for updates
}

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: {
    // Options included in calls to Feathers client.authenticate
    strategy: 'local-admin' // The authentication strategy Feathers should use
  },
  passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
  usernameField: 'username', // Our example database might use 'username' rather than 'email'
  redirectTo: '/login'
}

const App = () => {
  return (
    <Admin
      dataProvider={restClient(feathersClient, restClientOptions)}
      authProvider={authClient(feathersClient, authClientOptions)}
    >
      <Resource name='users' />
      <Resource
        name='companies'
        list={CompanyList}
        create={CompanyCreate}
        edit={CompanyEdit}
      />
      <Resource
        name='customers'
        icon={PeopleIcon}
        list={CustomerList}
        create={CustomerCreate}
        edit={CustomerEdit}
      />
      <Resource
        name='bids'
        icon={AttachMoneyIcon}
        list={BidList}
        create={BidChange}
        edit={BidChange}
      />
      <Resource
        name='admins'
        icon={Person}
        list={AdminList}
        create={AdminCreate}
        edit={AdminEdit}
      />
      <Resource
        name={'parts'}
        list={PartsList}
        show={PartsShow}
        icon={BuildIcon}
      />
      <Resource name='sessions' icon={AccessTimeIcon} list={SessionList} />
      <Resource
        name='settings'
        icon={SettingsIcon}
        list={SettingsList}
        edit={SettingsEdit}
      />
    </Admin>
  )
}

export default App
