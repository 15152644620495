/* global process */
import feathers, { rest, authentication } from '@feathersjs/client'
import hooks from './hooks'

const app = feathers()

const restClient = rest(process.env.REACT_APP_API_URL)

app.configure(restClient.fetch(window.fetch))

app.configure(
  authentication({
    jwtStrategy: 'jwt',
    storage: window.localStorage,
    header: 'authorization'
  })
)

app.hooks(hooks)

export default app
