import {
  Create,
  required,
  SimpleForm,
  TextInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin'
import React from 'react'
import { Typography } from '@material-ui/core'
import useCommonStyles from '../../hooks/useCommonStyles'
import PriceInput from '../../elements/PriceInput'
import RichText from './RichText'

const CompanyCreate = (props) => {
  const classes = useCommonStyles()
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source='companyName'
          label='CompanyName'
          validate={required()}
        />
        <TextInput
          source='email'
          label='Email'
          type='email'
          validate={required()}
        />
        <TextInput
          source='password'
          label='Password'
          type='password'
          validate={required()}
        />
        <Typography variant='h6' gutterBottom>
          Company Information
        </Typography>
        <TextInput
          source='address.address1'
          label='Address'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address.address2'
          label='Address (line 2)'
          formClassName={classes.inlineBlock}
        />
        <div />
        <TextInput
          source='address.city'
          label='City'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address.state'
          label='State'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address.zip'
          label='Zip'
          formClassName={classes.inlineBlock}
        />

        <TextInput source='phone' />
        <TextInput source='website' />
        <Typography variant='h6' gutterBottom>
          Bid configuration
        </Typography>
        <Typography variant='subtitle2' className={classes.fullWidth}>
          {
            'Information in this section will be visible to your customers. It will be added to bids.'
          }
        </Typography>
        <RichText />
        <NumberInput
          source='config.equipMarkup'
          label='Equipment markup'
          helperText={
            'In Percent (For example, if you enter 50, your equipment will be marked up by 50%)'
          }
          formClassName={classes.inlineBlock}
        />
        <NumberInput
          source='config.partsMarkup'
          label='Parts markup'
          helperText={
            'In Percent (For example, if you enter 50, your parts will be marked up by 50%)'
          }
          formClassName={classes.inlineBlock}
        />

        <Typography variant='h6' gutterBottom>
          Gensco integration
        </Typography>
        <TextInput source='gensco.customerID' label='customerID' />

        <Typography variant='h6' gutterBottom>
          Services & labors
        </Typography>

        <ArrayInput source='services'>
          <SimpleFormIterator>
            <TextInput
              source='name'
              label={'Name'}
              formClassName={classes.inlineBlock}
              validate={required()}
            />
            <PriceInput
              source='price'
              label={'Price'}
              formClassName={classes.inlineBlock}
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source='labors'>
          <SimpleFormIterator>
            <TextInput
              source='name'
              label={'Name'}
              formClassName={classes.inlineBlock}
              validate={required()}
            />
            <PriceInput
              source='price'
              label={'Price'}
              formClassName={classes.inlineBlock}
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source='permits'>
          <SimpleFormIterator>
            <TextInput
              source='name'
              label={'Name'}
              formClassName={classes.inlineBlock}
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source='jobTypes'>
          <SimpleFormIterator>
            <TextInput
              source='name'
              label={'Name'}
              formClassName={classes.inlineBlock}
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source='leadSource'>
          <SimpleFormIterator>
            <TextInput
              source='name'
              label={'Name'}
              formClassName={classes.inlineBlock}
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}

export default CompanyCreate
