import { Create, required, SimpleForm, TextInput } from 'react-admin'
import React from 'react'

const AdminCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source='username'
        label='Username'
        type='username'
        validate={required()}
      />
      <TextInput
        source='email'
        label='Email'
        type='email'
        validate={required()}
      />
      <TextInput
        source='password'
        label='Password'
        type='password'
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export default AdminCreate
