import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ReferenceField,
  TextField,
  SelectInput
} from 'react-admin'
import React from 'react'
import DateFilters from '../../elements/DateFilters'
import { EBidStatus } from './enums'

export const Filters = (props) => (
  <Filter {...props}>
    <SelectInput
      source='status'
      choices={Object.keys(EBidStatus).map((key) => ({
        id: key,
        name: EBidStatus[key]
      }))}
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

const BidsList = (props) => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<Filters />}
  >
    <Datagrid>
      <ReferenceField source='companyId' reference='companies' label='Company'>
        <TextField source='companyName' />
      </ReferenceField>
      <ReferenceField
        source='customerId'
        reference='customers'
        label='Customer'
      >
        <TextField source='identity.name' />
      </ReferenceField>

      <TextField source='status' label='Status' />

      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)

export default BidsList
