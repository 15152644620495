import {
  DateField,
  Edit,
  FormTab,
  required,
  TabbedForm,
  TextField,
  TextInput
} from 'react-admin'
import React from 'react'

const AdminEdit = (props) => (
  <Edit {...props}>
    <TabbedForm redirect={false}>
      <FormTab label='summary'>
        <TextField source='_id' label='Id' />
        <TextInput
          source='username'
          label='Username'
          type='text'
          validate={required()}
        />
        <TextInput
          source='email'
          label='Email'
          type='text'
          validate={required()}
        />
        <TextInput
          source='password'
          label='Password'
          type='password'
          validate={required()}
        />
        <DateField source='createdAt' label='Created at' showTime />
        <DateField source='updatedAt' label='Updated at' showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default AdminEdit
