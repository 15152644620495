import { Edit, SimpleForm, SaveButton, Toolbar } from 'react-admin'
import React from 'react'
import CustomEditSettings from './CustomEditSettings'

const SettingsEdit = (props) => {
  const UserEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  )

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <CustomEditSettings source='data' withLotId={false} />
      </SimpleForm>
    </Edit>
  )
}

export default SettingsEdit
