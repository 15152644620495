import { Typography } from '@material-ui/core'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'

export const formatPrice = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(Number(value || 0) / 100)
}

const PriceField = ({
  source,
  record,
  label,
  labelOnValue = true,
  style,
  ...props
}) => {
  const value = get(record, source)

  return (
    <div style={{ ...style }}>
      <Typography component='span' variant='caption' color='textSecondary'>
        {labelOnValue && label}
      </Typography>
      <div style={{ padding: '8px 0px 4px 0px' }}>
        <Typography component='span' variant='body2' {...props}>
          {(Number(value || 0) / 100).toFixed(2)}
        </Typography>
      </div>
    </div>
  )
}

PriceField.propTypes = {
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
  labelOnValue: PropTypes.bool
}

export default PriceField
