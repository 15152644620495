import { Datagrid, EditButton, List, TextField } from 'react-admin'
import React from 'react'

const SettingsList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source='type' label='Type' />
      <EditButton />
    </Datagrid>
  </List>
)

export default SettingsList
